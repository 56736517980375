@font-face {
  font-family: otter;
  src: url("./fonts/04B_30__.TTF");
}
@font-face {
  font-family: otter-sec;
  src: url("./fonts/ARCADE.TTF");
}
:root {
  --main: #34374c;
  --sec: #2c2e3e;
  --red: #ee2b47;
  --white: #f6f6f6;
}

* {
  /* font-family: otter; */
  margin: 0%;
}
body {
  background-color: var(--white) !important;
}
.navbar-custom {
  box-shadow: none;
  background-color: var(--white) !important;
  /* padding: 0px 60px; */
}
.iconsTop {
  margin-left: 20px;
}
.iconsTop:hover {
  cursor: pointer;
}
.arcade {
  font-family: otter-sec;
}
.mint:hover {
  cursor: pointer;
}
.head-main {
  margin: 20px;
}
.head-01 {
  /* text-align: center; */
  font-size: 340%;
  font-family: otter;
}
.head-02 {
  margin-top: 20px;
  font-family: otter-sec;
  font-size: 400%;
  padding: 20px;
}
.cont {
  padding: 0px 5%;
}
.para01 {
  font-family: otter-sec;
  color: var(--sec);
  font-size: 220%;
}
.para02 {
  text-align: center;
  font-family: otter-sec;
  color: var(--sec);
  font-size: 180%;
  padding-right: 50px;
}
/* .head03 {
  margin-top: 20px;
  font-family: otter;
  font-size: 180%;
  color: var(--sec);
  padding: 20px;
  text-align: center;
} */
.head03 {
  margin-top: 20px;
  font-family: otter;
  font-size: 100%;
  color: var(--sec);
  padding: 20px;
  text-align: center;
}
.head04 {
  font-family: otter;
  font-size: 180%;
  color: var(--sec);

  text-align: center;
}
.htitle{
  font-size: 18px;
}
.head05 {
  font-family: otter;
  font-size: 120%;
  color:white;
  top: 60%;
position: absolute;

width: 100%;
height: 100px;
  text-align: center;
}
.para03 {
  word-spacing: 15px;
  font-family: otter-sec;
  color: var(--sec);
  font-size: 180%;
  padding: 0px 10px;
}

.popImg {
  position: relative;
}

.popMsg {
  height: 100%;
  padding: 0px 15px 0px 5px;
  position: absolute;
  font-family: otter-sec;
  top: 1px;
  overflow: hidden;
  left: 1px;
  width: 95%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  overflow-y: hidden;
  background-color: transparent;
  box-shadow: none;
}
.imger:hover {
  cursor: pointer;
}

.mintMain{
  height: 100vh;
  width: 100%;
  background-color: #2c2e3e;
background: url('./img/mintbg.png') ;
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
}
.mintPageTitles{
text-align: center;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:var(--white)
}
.mintCard{
  /* height: 100%;
  width: 100%;
  background: url('./img/mintcard.png') ; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  /* position: relative; */
  
}
.MintH {
  font-family: otter;
  font-size: 350%;
  color: var(--red);
}

.address{
  color: var(--main);
  font-family:otter;
  font-size: 80%;
}
@media only screen and (max-width: 600px) {
  .head-01 {
    font-size: 250%;
  }
  .head-02 {
    font-size: 180%;
  }
}

@media only screen and (max-width: 450px) {
  .head-01 {
    font-size: 200%;
  }
  .head-02 {
    font-size: 200%;
  }
  .para01 {
    font-size: 150%;
  }
  .cont {
    padding: 0px 1%;
  }
  .para02 {
    padding-right: 0px;
  }
  .para03 {
    word-spacing: 4px;
    padding: 0px 10px;
  }
  .head04 {
    font-size: 160%;
  }
}


